import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Price from '../views/Price.vue'
import Introduction from '../views/Introduction.vue'
import Contant from '../views/support/Contant.vue'
import Help from '../views/support/Help.vue'
import Protocol from '../views/support/Protocol.vue'
import Anal from '../views/support/Anal.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
        children: [{
                path: "/about",
                name: "about",
                component: About,
                meta: {
                    title: "about"
                }
            },
            {
                path: "/price",
                name: "price",
                component: Price,
                meta: {
                    title: "price"
                }
            },
            {
                path: "/introduction",
                name: "introduction",
                component: Introduction,
                meta: {
                    title: "introduction"
                }
            },
            {
                path: "/contant",
                name: "contant",
                component: Contant,
                meta: {
                    title: "contant"
                }
            },
            {
                path: "/anal",
                name: "anal",
                component: Anal,
                meta: {
                    title: "anal"
                }
            },
            {
                path: "/protocol",
                name: "protocol",
                component: Protocol,
                meta: {
                    title: "protocol"
                }
            },
            {
                path: "/help",
                name: "help",
                component: Help,
                meta: {
                    title: "help"
                }
            }
        ]
    },
    // {
    //   path: '/about',
    //   name: 'About',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
]

const router = new VueRouter({
    routes
})

export default router