<template>
  <a-layout id="components-layout-demo-fixed">
    <a-layout-header :style="{ position: 'fixed', zIndex: 1, width: '100%'}">
      <div class="logo">
        <img src="~@/assets/logo.png" alt="logo">
      </div>
      <div class="logo-title">月上ERP管理系统</div>
      <a-menu
        theme="dark"
        mode="horizontal"
        :default-selected-keys="['1']"
        v-model="selectedkeys"
        :style="{ lineHeight: '64px' }"
      >
        <a-menu-item key="1">首页<router-link to="/" /></a-menu-item>
        <a-menu-item key="2">产品介绍<router-link to="/introduction" /></a-menu-item>
        <a-menu-item key="3">价格<router-link to="/price" /></a-menu-item>
        <a-menu-item key="4">联系我们<router-link to="/contant" /></a-menu-item>
        <a-menu-item key="5">品牌分析<router-link to="/anal" /></a-menu-item>
        <!-- <a-menu-item key="5">问题反馈<router-link to="/help" /></a-menu-item> -->
        <a-menu-item key="6">用户协议<router-link to="/protocol" /></a-menu-item>
        <!-- <a-menu-item key="7">测试地址<a @click="hrefClick"></a></a-menu-item> -->
      </a-menu>
    </a-layout-header>
    <a-layout-content :style="{ padding: '0 50px', marginTop: '64px' }">
      <a-breadcrumb :style="{ margin: '16px 0' }">
      </a-breadcrumb>
      <div v-if="selectedkeys[0] == '1'" :style="{ background: '#fff', padding: '24px', minHeight: '380px' }">
        <div style="width:80%">
          <a-row>
            <a-col :span="14">
              <div class="block-banner">
                <div class="block-fullview__title_new">一站式亚马逊店铺、商品管理工具</div>
                  <div class="block-fullview__describe_new">
                    <ul class="blockBannerWrap"><li>一次授权，即可自动整理各类销售数据和运营指标，并生成可视化数据报表。</li>
                    <li>关键词排名跟踪、库存提醒、跟卖提醒、自动索评、广告调价……一站式满足亚马逊卖家的店铺运营需求。</li><li>简单直观的可视化报表，帮助亚马逊卖家提高运营效率，节约人力成本和时间。</li></ul>
                </div>
              </div>
            </a-col>
            <a-col :span="10">
              <img src="~@/assets/banner.png">
            </a-col>
          </a-row>
        </div>
        <div style="width: 80%;text-align: center;margin: 30px auto auto auto;">
          <a-tabs default-active-key="1">
            <a-tab-pane key="1" tab="店铺分析">
              <a-row>
                <a-col :span="12">
                  <img src="~@/assets/feature-business.png" style="width: 100%">
                </a-col>
                <a-col :span="12">
                  <a-list style="margin-left: 20px;">
                    <a-list-item>支持多账号、多站点的销售运营数据汇总</a-list-item>
                    <a-list-item>实时销售利润分析，快速了解商品利润、毛利率及成本组成</a-list-item>
                    <a-list-item>提供商品的流量、排名、转化等多维度走势数据，挖掘店铺爆款</a-list-item>
                    <a-list-item>自动生成店铺周期性报告，支持导出，节省报表制作时间</a-list-item>
                  </a-list>
                </a-col>
              </a-row>
            </a-tab-pane>
            <a-tab-pane key="2" tab="商品管理" force-render>
              <a-row>
                <a-col :span="12">
                  <img src="~@/assets/feature-product.png" style="width: 100%">
                </a-col>
                <a-col :span="12">
                  <a-list style="margin-left: 20px;">
                    <a-list-item>通过接口同步店铺商品信息，支持快速修改Listing价格</a-list-item>
                    <a-list-item>查看商品指定关键词排名及所属类目排名，了解商品流量来源</a-list-item>
                    <a-list-item>支持商品智能调价，自动调价抢占购物车</a-list-item>
                  </a-list>
                </a-col>
              </a-row>
            </a-tab-pane>
            <a-tab-pane key="3" tab="计划管理">
              <a-row>
                <a-col :span="12">
                  <img src="~@/assets/block-all-bg.png" style="width: 100%">
                </a-col>
                <a-col :span="12">
                  <a-list style="margin-left: 20px;">
                    <a-list-item>实时跟踪发货、采购计划状态</a-list-item>
                    <a-list-item>随时调整计划，适应订单变化</a-list-item>
                    <a-list-item>智能提醒发货与采购，报障销售货源充足</a-list-item>
                  </a-list>
                </a-col>
              </a-row>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
      <router-view :style="{ background: '#fff', padding: '24px', minHeight: '380px' }"></router-view>
    </a-layout-content>
    <a-layout-footer :style="{ textAlign: 'center' }">
      <div id="footBack">
        <a-row type="flex" justify="space-around" >
          <a-col :span="4">
            <a-row type="flex" justify="space-around">
              <a-col :span="24">
                月上ERP
              </a-col>
              <a-col :span="24">
                联系方式 QQ:603415477
              </a-col>
              <a-col :span="24">
                邮箱 603415477@qq.com
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="4">
            <a-row type="flex" justify="space-around">
              <a-col :span="24">
                产品
              </a-col>
              <a-col :span="24">
                产品介绍
              </a-col>
              <a-col :span="24">
                价格
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="4">
            <a-row type="flex" justify="space-around">
              <a-col :span="24">
                公司
              </a-col>
              <a-col :span="24">
                联系我们
              </a-col>
              <!-- <a-col :span="24">
                问题反馈
              </a-col> -->
              <a-col :span="24">
                用户协议
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="4">
            <a-row>
              <img style="width: 150px" src="~@/assets/code.png">
            </a-row>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            ©2023 Created by Gahao
          </a-col>
        </a-row>
      </div>
    </a-layout-footer>
  </a-layout>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    //HelloWorld
  },
  data(){
    return{
      selectedkeys: ['1'],
    }
  },
  methods:{
    hrefClick(){
      window.open("https://moonsys.top");//打开一个新的标签页
    }
  }
}


</script>
<style>
  #components-layout-demo-fixed .logo img{
    width: 30px;
  }

  .logo-title {
      position: absolute;
      padding-left: 100px;
      color: white;
      font-size: 20px;
  }


  #components-layout-demo-fixed .logo {
    float: left;
  }

  #components-layout-demo-fixed {
    text-align: left;
  }

  .ant-menu {
    text-align: right;
    font-size: 20px;
  }

  .ant-menu .ant-menu-submenu {
    text-align: center;
    font-size: 20px;
  }

  #footBack {
    background: rgb(59, 59, 59);
    padding: '24px';
  }

  #footBack .ant-col {
    padding: 10px;
    color: white;
  }

  .block-banner {
    box-sizing: border-box;
    padding: 100px 80px 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.block-fullview__title_new {
    font-weight: 600;
    width: 800px;
    font-size: 60px;
    font-family: Adobe Heiti Std;
    color: #000;
    line-height: 70px;
}

.block-fullview__describe_new {
    padding-top: 80px;
    font-size: 16px;
    color: #666;
    line-height: 30px;
}

.ant-list-item {
  font-size: 20px;
  text-align: left;
}

</style>