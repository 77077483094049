<template>
  <div>
    <div class="title">根据店铺实际情况，选择合适方案</div>
    <div style="text-align: center;font-style: italic;">免费使用以及详情请联系客服</div>
    <div class="tab-plan">
      <a-tabs default-active-key="1">
        <a-tab-pane key="1" tab="按年购买">
          <img src="~@/assets/price-per-year.png">
        </a-tab-pane>
        <a-tab-pane key="2" tab="按月购买" force-render>
          <img src="~@/assets/price-per-month.png">
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Price',

  methods:{

  }
}
</script>
<style>
.title {
    font-size: 49px;
    font-weight: 700;
    padding: 40px 0;
    text-align: center;
    color: white;
    background-color: #59cce6;
}

.ant-tabs-tab {
  font-size: 30px;
  font-weight: 600;
}


.tab-plan {
  margin:0 auto;
  width:1000px;
}
</style>
